import React from 'react';
import { Button } from 'antd';
// import { DownOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import { TweenOneGroup } from 'rc-tween-one';
import { withRouter } from 'react-router-dom';
import BannerAnim, { Element } from 'rc-banner-anim';
import 'rc-banner-anim/assets/index.css';
import { History } from 'history';

interface Props {
  id: string;
  isMobile: boolean;
  dataSource: any;
  history: History;
}

const { BgElement } = Element;
class Banner extends React.PureComponent<Props> {
  goTo = (url: string) => () => {
    if (url.includes('airbnb')) {
      window.open(url, '_blank');
      return;
    }

    const { history } = this.props;
    history.push(url);
  }

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    const childrenToRender = dataSource.BannerAnim.children.map((item: any, i: number) => {
      const elem = item.BannerElement;
      const elemClassName = elem.className;
      delete elem.className;
      const { bg, textWrapper, title, content, button, url } = item;
      return (
        <Element key={i.toString()} {...elem} prefixCls={elemClassName}>
          <BgElement key="bg" {...bg} />
          <QueueAnim
            delay={200}
            key="text"
            {...textWrapper}
          >
            <div key="logo" {...title}>
              {typeof title.children === 'string' &&
                <img src={title.children} width="100%" alt="img" />
              }
            </div>
            <div key="content" {...content}>
              {content.children}
            </div>
            <Button ghost key="button" {...button} onClick={this.goTo(url)}>
              {button.children}
            </Button>
          </QueueAnim>
        </Element>
      );
    });

    return (
      <div {...props} {...dataSource.wrapper}>
        <TweenOneGroup
          key="bannerGroup"
          enter={{ opacity: 0, type: 'from' }}
          leave={{ opacity: 0 }}
          component=""
        >
          <div className="banner1-wrapper" key="wrapper">
            <BannerAnim type="across" dragPlay={false} key="BannerAnim" {...dataSource.BannerAnim}>
              {childrenToRender}
            </BannerAnim>
          </div>
        </TweenOneGroup>
      </div>
    );
  }
}

// @ts-ignore
export default withRouter(Banner);
