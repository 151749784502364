import React, { useState, useCallback } from 'react';
import { Divider, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import {
  MdDirectionsBike,
  MdLocalParking,
  MdWifi,
  MdTv,
  MdChildCare,
  MdLocalLaundryService,
  MdLocalCafe,
  MdKitchen,
  MdNature,
} from 'react-icons/md';
import { enquireScreen } from 'enquire-js';

// import { ReactComponent as Grill } from '../images/equipments/grill.svg';
import { ReactComponent as SingleBed } from '../images/equipments/singlebed.svg';
import { ReactComponent as DoubleBed } from '../images/equipments/doublebed.svg';
import { ReactComponent as Games } from '../images/equipments/games.svg';
import { ReactComponent as Bath } from '../images/equipments/bath.svg';
import { ReactComponent as Hanger } from '../images/equipments/hangers.svg';
import { ReactComponent as Dishwater } from '../images/equipments/dishwater.svg';
import { ReactComponent as Microwave } from '../images/equipments/microwave.svg';
import { ReactComponent as HairDryer } from '../images/equipments/hairdryer.svg';
import { ReactComponent as DeckChair } from '../images/equipments/deckchair.svg';
import { ReactComponent as Deck } from '../images/equipments/deck.svg';

export default function Description() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);


  React.useEffect(() => {
    enquireScreen((b: boolean) => {
      setIsMobile(!!b);
    });

  }, []);

  const importAll = (r: any) => {
    return r.keys().map(r);
  }

  // @ts-ignore
  const photos = importAll(require.context('../images/todiscover/', false, /\.(png|jpe?g|svg)$/)).map(photo => ({ src: photo, width: 24, height: 16 }));
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div className={`description-container ${isMobile ? "description-container-mobile" : ""}`}>
      <Divider className="divider" plain><FormattedMessage id="global.description" /></Divider>
      <FormattedMessage id="description.text" />

      <Divider className="divider" plain><FormattedMessage id="global.equipments" /></Divider>

      <div>
        <Row>
          <Col span={12}>
            <DoubleBed fontSize={18} className="logo-equipment" />1 <FormattedMessage id="equipment.doublebed" />
          </Col>
          <Col span={12}>
            <SingleBed fontSize={18} className="logo-equipment" />2 <FormattedMessage id="equipment.simplebed" />
          </Col>
          <Col span={12}>
            <MdChildCare fontSize={18} className="logo-equipment" />1 <FormattedMessage id="equipment.babybed" />
          </Col>
          <Col span={12}>
            <MdTv fontSize={18} className="logo-equipment" />2 <FormattedMessage id="equipment.tv" />
          </Col>
          <Col span={12}>
            <HairDryer fontSize={18} className="logo-equipment" /><FormattedMessage id="equipment.hairdryer" />
          </Col>
          <Col span={12}>
            <MdLocalParking fontSize={18} className="logo-equipment" /><FormattedMessage id="equipment.parking" />
          </Col>
          <Col span={12}>
            <MdNature fontSize={18} className="logo-equipment" /><FormattedMessage id="equipment.garden" />
          </Col>
          <Col span={12}>
            <Deck fontSize={18} className="logo-equipment" /><FormattedMessage id="equipment.deck" />
          </Col>
          <Col span={12}>
            <DeckChair fontSize={18} className="logo-equipment" />2 <FormattedMessage id="equipment.deckchair" />
          </Col>
          <Col span={12}>
            <MdWifi fontSize={18} className="logo-equipment" /><FormattedMessage id="equipment.wifi" />
          </Col>
          <Col span={12}>
            <Bath fontSize={18} className="logo-equipment" /><FormattedMessage id="equipment.bath" />
          </Col>
          <Col span={12}>
            <MdDirectionsBike fontSize={18} className="logo-equipment" />2 <FormattedMessage id="equipment.bikes" />
          </Col>
          <Col span={12}>
            <Games fontSize={18} className="logo-equipment" /><FormattedMessage id="equipment.games" />
          </Col>
          <Col span={12}>
            <MdKitchen fontSize={18} className="logo-equipment" /><FormattedMessage id="equipment.fridge" />
          </Col>
          <Col span={12}>
            <Dishwater fontSize={18} className="logo-equipment" /><FormattedMessage id="equipment.dishwater" />
          </Col>
          <Col span={12}>
            <Microwave fontSize={18} className="logo-equipment" /><FormattedMessage id="equipment.microwave" />
          </Col>
          <Col span={12}>
            <MdLocalLaundryService fontSize={18} className="logo-equipment" /><FormattedMessage id="equipment.washingmachine" />
          </Col>
          <Col span={12}>
            <MdLocalCafe fontSize={18} className="logo-equipment" /><FormattedMessage id="equipment.coffee" />
          </Col>
          <Col span={12}>
            <Hanger fontSize={18} className="logo-equipment" /><FormattedMessage id="equipment.hangers" />
          </Col>
          {/* <Col span={12}>
            <Grill fontSize={18} className="logo-equipment" /><FormattedMessage id="equipment.barbecue" />
          </Col> */}
        </Row>
      </div>

      <Divider className="divider" plain><FormattedMessage id="global.discover" /></Divider>
      <FormattedMessage id="description.todiscover" />

      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x: any) => ({
                ...x,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div >
  );
}

