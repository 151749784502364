import React from 'react';

export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: '/images/logo.svg',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: '/',
        className: 'header0-item',
        children: {
          href: '/',
          children: [
            {
              children: (
                <span>
                  <p>Accueil</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: '/description',
        className: 'header0-item',
        children: {
          href: '/description',
          children: [
            {
              children: (
                <span>
                  <p>Description</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: '/gallery',
        className: 'header0-item',
        children: {
          href: '/gallery',
          children: [
            {
              children: (
                <span>
                  <p>Galerie</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: '/expositions',
        className: 'header0-item',
        children: {
          href: '/expositions',
          children: [
            {
              children: (
                <span>
                  <p>Expositions</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item4',
        className: 'header0-item',
        children: {
          href: 'https://www.airbnb.fr/rooms/43859110',
          children: [
            {
              children: (
                <span>
                  <p>Réserver !</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};

export const Banner00DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: {
          // className: 'banner1-title',
          // children:
          //   'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: 'Bienvenue au Gîte du Nid de Chien',
        },
        url: "/description",
        button: { className: 'banner1-button', children: 'En savoir plus' },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg1' },
        title: {
          // className: 'banner1-title',
          // children:
          //   'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: 'Une décoration atypique',
        },
        url: "/gallery",
        button: { className: 'banner1-button', children: 'Voir les photos' },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg2' },
        title: {
          // className: 'banner1-title',
          // children:
          //   'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: 'Au coeur de la nature, profitez de nombreux équipements mis à disposition gratuitement',
        },
        url: "/description",
        button: { className: 'banner1-button', children: 'En savoir plus' },
      },
      {
        name: 'elem3',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg3' },
        title: {
          // className: 'banner1-title',
          // children:
          //   'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        content: {
          className: 'banner1-content',
          children: 'Retrouvez des expositions d\'oeuvres d\'artistes locaux',
        },
        url: "/expositions",
        button: { className: 'banner1-button', children: 'Voir les expositions' },
      },
      {
        name: 'elem4',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg4' },
        title: {
          // className: 'banner1-title',
          // children:
          //   'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
        },
        url: "https://www.airbnb.fr/rooms/43859110",
        content: {
          className: 'banner1-content',
          children: 'On attend plus que vous !',
        },
        button: { className: 'banner1-button', children: 'Réserver' },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          <p>
            Gîte du Nid de Chien - 8 chemin du pré - 59190 CAESTRE
          </p>
        </span>
      </span>
    ),
  },
};

export const expositions = [{
  img: "/images/expositions/muge3.jpg",
  artist: "Muge Kaymakcan",
  url: "https://www.instagram.com/mugekaymakcanartt/",
  title: "",
  description: "Peintre",
  endDate: "2032-09-30",
},{
  img: "/images/expositions/muge2.jpg",
  artist: "Muge Kaymakcan",
  url: "https://www.instagram.com/mugekaymakcanartt/",
  title: "",
  description: "Peintre",
  endDate: "2032-09-30",
},{
  img: "/images/expositions/muge1.jpg",
  artist: "Muge Kaymakcan",
  url: "https://www.instagram.com/mugekaymakcanartt/",
  title: "",
  description: "Peintre",
  endDate: "2032-09-30",
},{
  img: "/images/expositions/lievre.jpg",
  artist: "Le lièvre",
  url: "https://www.facebook.com/lelievreaffiche/",
  title: "Musée des Augustins",
  description: "Graphiste",
  endDate: "2032-09-30",
},{
  img: "/images/expositions/jouissance.jpg",
  artist: "Sophie O'Miel",
  url: "https://www.facebook.com/profile.php?id=100007784738068",
  title: "\"Jouissance\"",
  description: "Sculpteur",
  endDate: "2023-09-30",
}, {
  img: "/images/expositions/pause.jpg",
  artist: "Sophie O'Miel",
  url: "https://www.facebook.com/profile.php?id=100007784738068",
  title: "\"Temps de pause\"",
  description: "Sculpteur",
  endDate: "2023-09-30",
}, {
  img: "/images/expositions/foule.jpg",
  artist: "Alain Descamps",
  url: "https://www.facebook.com/profile.php?id=100063462095272",
  title: "\"Foule en couleur\"",
  description: "Peintre - Plasticien",
  endDate: "2032-09-30",
}, {
  img: "/images/expositions/3oeil.jpg",
  artist: "Alain Descamps",
  url: "https://www.facebook.com/profile.php?id=100063462095272",
  title: "\"Le 3ème Oeil\"",
  description: "Peintre - Plasticien",
  endDate: "2032-09-30",
}, {
  img: "/images/expositions/floraison.jpg",
  artist: "Alain Descamps",
  url: "https://www.facebook.com/profile.php?id=100063462095272",
  title: "\"La floraison dorée\"",
  description: "Peintre - Plasticien",
  endDate: "2032-09-30",
}, {
  img: "/images/expositions/hommedebout.jpg",
  artist: "Jean-Marc Escherich",
  url: "https://mosaiquesennord.com",
  title: "\"L'homme debout\"",
  description: "Mosaîste",
  endDate: "2032-09-30",
}, {
  img: "/images/expositions/fredmali.jpg",
  artist: "Frédérique Malinverno",
  url: "http://le-maitre-malin.eklablog.com/atelier-creatif-c14283452",
  title: "\"La sorcière\"",
  description: "Créatrice de marionnettes",
  endDate: "2032-09-30",
}, {
  img: "/images/expositions/lamare.jpg",
  artist: "Ginette Demassiet",
  url: "",
  title: "\"La mare\"",
  description: "Peintre",
  endDate: "2032-09-30",
}, {
  img: "/images/expositions/fd.jpg",
  artist: "Ginette Demassiet",
  url: "",
  title: "\"Pots et dentelles\"",
  description: "Peintre",
  endDate: "2032-09-30",
}, {
  img: "/images/expositions/priolimosaic.jpg",
  artist: "Catherine Prioli",
  url: "https://www.facebook.com/catherine.prioli",
  title: "\"Un p'tit gars d'Hazebrouck\"",
  description: "Mosaïste",
  endDate: "2022-09-20",
}, {
  img: "/images/expositions/homme_miroir.jpg",
  artist: "François Vanhove",
  url: "http://www.francoisvanhove-sculpteur.fr/",
  title: "\"Homme miroir\"",
  description: "Sculpteur",
  endDate: "2022-09-20",
}, {
  img: "/images/expositions/flamenco.jpg",
  artist: "François Vanhove",
  url: "http://www.francoisvanhove-sculpteur.fr/",
  title: "\"Flamenco\"",
  description: "Sculpteur",
  endDate: "2022-09-20",
}, {
  img: "/images/expositions/sirene_deux.jpg",
  artist: "François Vanhove",
  url: "http://www.francoisvanhove-sculpteur.fr/",
  title: "\"Sirène 2\"",
  description: "Sculpteur",
  endDate: "2022-09-20",
}, {
  img: "/images/expositions/danseuse1.jpg",
  artist: "François Vanhove",
  url: "http://www.francoisvanhove-sculpteur.fr/",
  title: "\"Danseuse\"",
  description: "Sculpteur",
  endDate: "2022-09-20",
}, {
  img: "/images/expositions/buste.jpg",
  artist: "Jean-Marc Escherich",
  url: "https://mosaiquesennord.com",
  title: "\"L'homme fleur\"",
  description: "Mosaïste",
  endDate: "2022-09-30",
}, {
  img: "/images/expositions/dodo.jpg",
  artist: "Jérôme Delisse",
  title: "\"Le dodo\"",
  description: "Mosaîque singulière",
  url: "",
  endDate: "2032-09-30",
}, {
  img: "/images/expositions/vouivre.jpg",
  artist: "François Vanhove",
  url: "http://www.francoisvanhove-sculpteur.fr/",
  title: "\"Vouivre\"",
  description: "Sculpteur",
  endDate: "2021-09-20",
}, {
  img: "/images/expositions/vfat.jpg",
  artist: "François Vanhove",
  url: "http://www.francoisvanhove-sculpteur.fr/",
  title: "\"Vieille femme au turban\"",
  description: "Sculpteur",
  endDate: "2021-09-20",
}, {
  img: "/images/expositions/tourmente.jpg",
  artist: "François Vanhove",
  url: "http://www.francoisvanhove-sculpteur.fr/",
  title: "\"Le Tourmenté \"",
  description: "Sculpteur",
  endDate: "2020-11-01",
}, {
  img: "/images/expositions/venus.jpg",
  artist: "François Vanhove",
  url: "http://www.francoisvanhove-sculpteur.fr/",
  title: "\"Vénus\"",
  description: "Sculpteur",
  endDate: "2032-09-30",
}, {
  img: "/images/expositions/bluesman.jpg",
  artist: "François Vanhove",
  url: "http://www.francoisvanhove-sculpteur.fr/",
  title: "\"Le Bluesman\"",
  description: "Sculpteur",
  endDate: "2020-11-01",
}, {
  img: "/images/expositions/nacera.jpg",
  artist: "Nacera Oussad",
  url: "https://www.artmajeur.com/fr/n-oussad/artworks",
  title: "\"L'oeil de Medusa\"",
  description: "Peintre",
  endDate: "2020-11-01",
}, {
  img: "/images/expositions/soleilenergisant.jpg",
  artist: "Nacera Oussad",
  url: "https://www.artmajeur.com/fr/n-oussad/artworks",
  title: "\"Soleil énergisant\"",
  description: "Peintre",
  endDate: "2020-11-01",
}, {
  img: "/images/expositions/laprof.jpg",
  artist: "Aleyn Kidd",
  url: "https://www.facebook.com/AleynKidd",
  title: "\"La professeure\"",
  description: "Dessinatrice",
  endDate: "2032-09-30",
}, {
  img: "/images/expositions/lesorcier.jpg",
  artist: "Aleyn Kidd",
  url: "https://www.facebook.com/AleynKidd",
  title: "\"Le Sorcier\"",
  description: "Dessinatrice",
  endDate: "2032-09-30",
}, {
  img: "/images/expositions/angenoir.jpg",
  artist: "Aleyn Kidd",
  url: "https://www.facebook.com/AleynKidd",
  title: "\"L'ange noir\"",
  description: "Dessinatrice",
  endDate: "2032-09-30",
}];
