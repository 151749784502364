import React from 'react';
import { enquireScreen } from 'enquire-js';

import Banner0 from '../components/Banner0';
import {
  Banner00DataSource,
} from '../data.source';

let isMobile: boolean;
enquireScreen((b: boolean) => {
  isMobile = b;
});

const { location = { port: null } } = typeof window !== 'undefined' ? window : {};

interface Props { }
interface State {
  isMobile: boolean;
  show: boolean;
}

export default class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port,
    };
  }

  render() {
    return (
      <div>
        <Banner0
          id="Banner0_0"
          key="Banner0_0"
          dataSource={Banner00DataSource}
          isMobile={this.state.isMobile}
        />
      </div>
    );
  }
}
