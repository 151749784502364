import React from 'react';
import { Row, Col, Card, Divider } from 'antd';
import { enquireScreen } from 'enquire-js';
import Meta from 'antd/lib/card/Meta';

import { expositions } from '../data.source';
import { FormattedMessage } from 'react-intl';

interface IExposition {
  img: string;
  artist: string;
  description: string;
  title: string;
  url: string;
  endDate: string;
}

interface Props { }
interface State {
  isMobile: boolean;
}

let isMobile: boolean;
enquireScreen((b: boolean) => {
  isMobile = b;
});

export default class Exposition extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isMobile,
    };
  }

  componentDidMount() {
    enquireScreen((b: boolean) => {
      this.setState({ isMobile: !!b });
    });
  }

  goToArtistWebsite = (url: string) => () => {
    window.open(url, "_blank");
  }

  render() {
    const { isMobile } = this.state;
    const currentExpos = expositions.filter((expo: IExposition) => new Date(expo.endDate) >= new Date())
    const oldExpos = expositions.filter((expo: IExposition) => new Date(expo.endDate) <= new Date())
    return (
      <div className={isMobile ? "exposition-card-wrapper-mobile" : "exposition-card-wrapper"}>
        <Row gutter={32} align="middle">
          <Divider className="divider" plain><FormattedMessage id="exposition.current" /></Divider>
          {currentExpos.map((expo: IExposition) => (
            <Col span={isMobile ? 24 : 6} className="exposition-col">
              <Card
                onClick={expo.url ? this.goToArtistWebsite(expo.url) : undefined}
                hoverable
                cover={<img alt={expo.artist} src={expo.img} />}
              >
                <Meta className="meta-card" title={`${expo.artist} ${expo.title && `- ${expo.title}`}`} description={expo.description} />
              </Card>
            </Col>
          ))}
          {oldExpos.length > 0 ? <Divider className="divider" plain><FormattedMessage id="exposition.history" /></Divider> : null}
          {oldExpos.map((expo: IExposition) => (
            <Col span={isMobile ? 24 : 6} className="exposition-col">
              <Card
                onClick={expo.url ? this.goToArtistWebsite(expo.url) : undefined}
                hoverable
                cover={<img alt={expo.artist} src={expo.img} />}
                className="meta-card"
              >
                <Meta title={`${expo.artist} - ${expo.title}`} description={expo.description} />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}
