import { useIntl } from 'react-intl'
import { PrimitiveType } from 'intl-messageformat';

// "import type" ensures en messages aren't bundled by default
import * as sourceOfTruth from '../locales/fr.json';
// Note: in order to use "import type" you'll need Babel >= 7.9.0 and/or TypeScript >= 3.8.
// Otherwise, you can use a normal import and accept to always bundle one language + the user required one

export type LocaleMessages = typeof sourceOfTruth;
export type LocaleKey = keyof LocaleMessages;

export function useFormatMessage(): (
  id: LocaleKey, // only accepts valid keys, not any string
  values?: Record<string, PrimitiveType>
) => string {
  const intl = useIntl();
  return (id, values) => intl.formatMessage({ id }, values);
}

type SupportedLocales = 'en' | 'fr';

// return type on this signature enforces that all languages have the same translations defined
export function importMessages(
  locale: SupportedLocales
): Promise<LocaleMessages> {
  switch (locale) {
    case 'en':
      return import('../locales/en.json');
    case 'fr':
      return import('../locales/fr.json');
  }
}

// export const IntlProvider: React.FC<
//   Omit<React.ComponentProps<typeof IntlProvider_>, 'messages'> & {
//     messages: LocaleMessages;
//   }
//   > = props => <IntlProvider_ { ...props } />;

export const getBrowserLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    return navigator.languages[0].substr(0, 2);
  }
  // @ts-ignore
  if (navigator.userLanguage) {
    // IE only
    // @ts-ignore
    return navigator.userLanguage.substr(0, 2);
  }
  // latest versions of Chrome, Firefox, and Safari set this correctly
  return navigator.language.substr(0, 2);
};