import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Facebook } from '../images/facebook.svg';
import { ReactComponent as Instagram } from '../images/instagram.svg';
import { ReactComponent as Airbnb } from '../images/airbnb.svg';
// import { ReactComponent as LogoSmall } from '../images/logo_small.svg';

interface Props {
  id: string;
  dataSource: any;
  isMobile: boolean;
}

class Footer extends React.PureComponent<Props> {
  goTo = (url: string) => () => {
    window.open(url, "_blank");
  }

  render() {
    const { isMobile, ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    return (
      <div {...props} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <TweenOne
            animation={{ y: '+=30', opacity: 0, type: 'from' }}
            key="footer"
            {...dataSource.copyright}
            className="footer-text-container"
          >
            {/* <LogoSmall className="logo-small-footer" /> */}
            <div>
              <FormattedMessage id="footer.text" />
            </div>
            {!isMobile ? <div className="logo-container">
              <Facebook className="svg-footer facebook" onClick={this.goTo("https://www.facebook.com/Le-g%C3%AEte-du-nid-de-chien-101955764907073")} />
              <Instagram className="svg-footer" onClick={this.goTo("https://www.instagram.com/legiteduniddechien")} />
              <Airbnb className="svg-footer" onClick={this.goTo("https://www.airbnb.fr/rooms/43859110")} />
            </div> : null}
          </TweenOne>
        </OverPack>
      </div >
    );
  }
}

export default Footer;
