import React from 'react';

export default class P404 extends React.Component {
  render() {
    return (
      <div>
        Page not found
      </div>
    );
  }
}
