/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Nav0 from './Nav0';
import Footer0 from './Footer0';

import {
  Nav00DataSource,
  Footer00DataSource,
} from '../data.source';
import '../less/antMotionStyle.less';

let isMobile: boolean;
enquireScreen((b: boolean) => {
  isMobile = b;
});

const { location = { port: null } } = typeof window !== 'undefined' ? window : {};

interface Props { }
interface State {
  isMobile: boolean;
  show: boolean;
}

export default class Layout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port,
    };
  }

  componentDidMount() {
    enquireScreen((b: boolean) => {
      this.setState({ isMobile: !!b });
    });

    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
  }

  render() {
    const { isMobile, show } = this.state;
    const { children } = this.props;
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          // @ts-ignore
          this.dom = d;
        }}
      >
        {show ? (
          <>
            <Nav0
              id="Nav0_0"
              key="Nav0_0"
              dataSource={Nav00DataSource}
              isMobile={isMobile}
            />
            {children}
            <Footer0
              id="Footer0_0"
              key="Footer0_0"
              dataSource={Footer00DataSource}
              isMobile={isMobile}
            />
          </>) : null}
      </div>
    );
  }
}
