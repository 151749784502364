import React from 'react';
import TweenOne from 'rc-tween-one';
import { Menu } from 'antd';
import { getChildrenToRender } from '../utils/utils';
import { History } from 'history';
import { withRouter } from 'react-router';
import { noop } from 'lodash';
import { ReactComponent as Logo } from '../images/logo.svg';
import { ReactComponent as Facebook } from '../images/facebook.svg';
import { ReactComponent as Instagram } from '../images/instagram.svg';
import { ReactComponent as Airbnb } from '../images/airbnb.svg';

const { Item, SubMenu } = Menu;

interface Props {
  id: string;
  dataSource: any;
  isMobile: boolean;
  history: History;
  location: Location;
}

interface State {
  phoneOpen: boolean | undefined;
  selectedKeys: string[];
}

class Header extends React.Component<Props, State>  {
  constructor(props: Props) {
    super(props);
    this.state = {
      phoneOpen: undefined,
      selectedKeys: [props.history.location.pathname],
    };
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };

  componentDidUpdate(prevProps: Props) {
    const { location: { pathname } } = this.props;
    const { location: { pathname: oldPathname } } = prevProps;
    if (pathname !== oldPathname) {
      this.setState({ selectedKeys: [pathname] });
    }
  }

  goTo = (url: string) => () => {
    const { history, isMobile } = this.props;
    const { phoneOpen } = this.state;
    if (url.includes('https')) {
      window.open(url, '_blank');
      return;
    }
    this.setState({ selectedKeys: [url], phoneOpen: isMobile ? undefined : phoneOpen }, () => {
      history.push(url);
    })
  }

  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const { phoneOpen, selectedKeys } = this.state;
    const navData = dataSource.Menu.children;
    const navChildren = navData.map((item: any) => {
      const { children: a, subItem, ...itemProps } = item;
      if (subItem) {
        return (
          <SubMenu
            key={item.name}
            {...itemProps}
            title={
              <div
                {...a}
                className={`header0-item-block ${a.className}`.trim()}
              >
                {a.children.map(getChildrenToRender)}
              </div>
            }
            popupClassName="header0-item-child"
          >
            {subItem.map(($item: any, ii: number) => {
              const { children: childItem } = $item;
              const child = childItem.href ? (
                <div onClick={this.goTo(childItem.href)} {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                </div>
              ) : (
                  <div {...childItem}>
                    {childItem.children.map(getChildrenToRender)}
                  </div>
                );
              return (
                <Item key={$item.name || ii.toString()} {...$item}>
                  {child}
                </Item>
              );
            })}
          </SubMenu>
        );
      }

      return (
        <Item key={item.name} {...itemProps}>
          <div onClick={a.href ? this.goTo(a.href) : noop} {...a} className={`header0-item-block ${a.className}`.trim()}>
            {a.children.map(getChildrenToRender)}
          </div>
        </Item>
      );
    });


    if (isMobile) {
      navChildren.push((
        <Item key="social" >
          <Facebook className="svg-header facebook" onClick={this.goTo("https://www.facebook.com/Le-g%C3%AEte-du-nid-de-chien-101955764907073")} />
          <Instagram className="svg-header" onClick={this.goTo("https://www.instagram.com/legiteduniddechien")} />
          <Airbnb className="svg-header" onClick={this.goTo("https://www.airbnb.fr/rooms/43859110")} />
        </Item>
      ))
    }

    const moment = phoneOpen === undefined ? 300 : null;

    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: 'from' }}
        {...dataSource.wrapper}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
        >
          <TweenOne
            animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
            {...dataSource.logo}
          >
            <Logo onClick={this.goTo('/')} className="logo" />
            {/* <img onClick={this.goTo('/')} width="100%" src={dataSource.logo.children} alt="img" /> */}
          </TweenOne>
          {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick();
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          <TweenOne
            {...dataSource.Menu}
            animation={
              isMobile
                ? {
                  height: 0,
                  duration: 300,
                  onComplete: (e) => {
                    if (this.state.phoneOpen) {
                      e.target.style.height = 'auto';
                    }
                  },
                  ease: 'easeInOutQuad',
                }
                : null
            }
            moment={moment}
            reverse={!!phoneOpen}
          >
            <Menu
              mode={isMobile ? 'inline' : 'horizontal'}
              defaultSelectedKeys={['/']}
              theme="dark"
              selectedKeys={selectedKeys}
            >
              {navChildren}
            </Menu>
          </TweenOne>
        </div>
      </TweenOne>
    );
  }
}

// @ts-ignore
export default withRouter(Header);
