import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getBrowserLanguage, importMessages, LocaleMessages } from './utils/i18n';
import { IntlProvider } from 'react-intl'

import Layout from './components/Layout';
import Home from './pages/Home';
import Description from './pages/Description';
import p404 from './pages/p404';
import Exposition from './pages/Exposition';
import Gallery from './pages/Gallery';

const App: React.FC = () => {
  const locale = getBrowserLanguage() || 'fr';
  const [messages, setMessages] = React.useState<LocaleMessages | null>(null);
  React.useEffect(() => {
    importMessages(locale).then(setMessages);
  }, [locale]);

  return messages ? (
    <IntlProvider locale={getBrowserLanguage()} messages={messages}>
      <Layout>
        <Switch>
          <Route
            path="/"
            exact
            component={Home}
          />
          <Route
            path="/description"
            exact
            component={Description}
          />
          <Route
            path="/gallery"
            exact
            component={Gallery}
          />
          <Route
            path="/expositions"
            exact
            component={Exposition}
          />
          <Route component={p404} />
        </Switch>
      </Layout>
    </IntlProvider>
  ) : null;
}

export default App;
